import React from 'react'

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-4 m-col-12 section">
                        <a href="" className="logo"></a>
                        <div className="info address">90B Delhi - Jaipur Expy, Sector 18, Gurugram, Haryana - 122001</div>
                        <div className="info email">
                            <a href="mailto:support@saralpay.com?subject=Communication with Saraloan" className="link">support@saralpay.com</a>
                        </div>
                    </div>
                    <div className="col-4 m-col-12 section">
                        <strong className="heading">Products</strong>
                        <ul className="nav">
                            <li>
                                <a className="link">Pay Later</a>
                            </li>
                            <li>
                                <a className="link">Vendor Financing</a>
                            </li>
                            <li>
                                <a className="link">Receivable Financing</a>
                            </li>
                            <li>
                                <a className="link">Cash Advance</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-4 m-col-12 section">
                        <strong className="heading">More</strong>
                        <ul className="nav">
                            <li>
                                <a href="https://documenter.getpostman.com/view/9478456/TzRYe5Mf" target="_blank" className="link">API Documentation</a>
                            </li>
                            <li>
                                <a href="https://github.com/saraloan/retailersdk" target="_blank" className="link">Android SDK</a>
                            </li>
                            <li>
                                <a href="/terms-and-conditions" className="link">Terms &amp; Conditions</a>
                            </li>
                            <li>
                                <a href="/privacy-policy" className="link">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="/regulatory-and-compliance" className="link">Regulatory &amp; Compliance</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="copyright-info">
                    <div className="container container-sm text-center">
                        <p className="text"> Copyright © 2021. Saraloan Technologies Private Limited. All Rights Reserved. </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer