import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <header>
            <div className="container">
                <Link to="/" className="logo router-link-exact-active router-link-active"></Link>
                <div className="menu-btn">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="nav-wrapper">
                    <nav className="nav">
                        <a target="_blank" rel="noreferrer" href="https://app.saraloan.in/loan-application" className="link">Retailer's Login</a>
                        <a target="_blank" rel="noreferrer" href="https://web.saraloan.in" className="link">Distributor's Login</a>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default Header