import React from 'react'
import Footer from '../components/layouts/Footer'
import Header from '../components/layouts/Header'
import homeBg from '../assets/img/home-bg.png'
import apiScreenshot from '../assets/img/api-screenshot.png'
import 'remixicon/fonts/remixicon.css';
import '../assets/svgs/constellation.css'
import '../assets/svgs/constellation-light.css'


const Home = () => {
    return (
        <>
            <Header />
            <div className="home">
                <section className="banner home-banner pattern-constellation-light">
                    <div className="overlay">
                        <div className="content">
                            <div className="container">
                                <div className="row">
                                    <div className="img col-6 m-col-12">
                                        <img src={homeBg} alt="" />
                                    </div>
                                    <div className="text col-6 m-col-12">
                                        <h1 className="heading">India's First Credit Ecosystem</h1>
                                        <p>
                                            We're Connecting NBFCs, Banks & Enterprises through our plug & play technology stack.
                                        </p>
                                        <br />
                                        <a href="mailto:support@saralpay.com" className="btn btn-accent"> Contact Us </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper text-center intro pattern-constellation">
                    <div className="container">
                        <div className="head">
                            <h3 className="heading">A plug-and-play ecosystem for NBFCs, Banks and Enterprises</h3>
                            <p className="text">
                                Saralpay operates as an interface between small businesses, large banks and non-banking financial institutions. It works by providing the necessary infrastructure for these parties to offer credit products to customers.
                            </p>
                        </div>
                        <div className="content">
                            <div className="row stats">
                                <div className="col-4 m-col-12 stat">
                                    <strong className="number"> <span className="currency">₹</span>400+</strong>
                                    <span className="desc">Crores Disbursed</span>
                                </div>
                                <div className="col-4 m-col-12 stat">
                                    <strong className="number">40K+</strong>
                                    <span className="desc">Total Disbursements</span>
                                </div>
                                <div className="col-4 m-col-12 stat">
                                    <strong className="number">75+</strong>
                                    <span className="desc">Anchor Partners</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper colored pattern-constellation-light">
                    <div className="container">
                        <div className="content">
                            <div className="row">
                                <div className="col-4 m-col-12">
                                    <div>
                                        <h2 className="heading">
                                            Most Advanced Credit Operating System for <span className="text-accent">NBFCs</span>
                                        </h2>
                                        <p>
                                            Saralpay is a decentralised ecosystem which has been created to bridge the ecosystem of enterprises, NBFCs and Banks through a reliable, high performance technology stack. Saralpay has developed a unique credit operating system that can be used for any kind of credit product. We offer an easy and quick way for banks and NBFCs to expand their product portfolio to cater to the needs of their clients.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-1 m-col-0"></div>
                                <div className="col-7 m-col-12">
                                    <div className="row">
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent  ri-rocket-fill"></i>
                                            <div>
                                                <strong className=""> No integration required, ready to start instantly </strong>
                                                <p>
                                                    Minimal workload at your end, Saralpay does all the work for you. Onboarding takes only 1 day.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-user-voice-fill"></i>
                                            <div>
                                                <strong className="">Access to Demands</strong>
                                                <p>Once NBFCs and Banks plug into our system, they can get access to demand on our platform without doing anything</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-shield-star-fill"></i>
                                            <div>
                                                <strong className="">Meets all the compliance requirements</strong>
                                                <p>
                                                    One-click downloadable reports to meet all your compliance & accounting needs as per RBI guidelines
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-settings-fill"></i>
                                            <div>
                                                <strong className="">Manage your own Portfolio Control </strong>
                                                <p>
                                                    Track your portfolio granularly and set your own control parameters as per your business needs
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-timer-flash-fill"></i>
                                            <div>
                                                <strong className="">Automate Collections </strong>
                                                <p>
                                                    Our collection stack and reconciliation helps you take repayments without putting any efforts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-auction-fill"></i>
                                            <div>
                                                <strong className="">Manage Post Disbursal Processes</strong>
                                                <p>We provide end-to-end tools to simplify your loan resolution process.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="our-products" className="wrapper flow">
                    <div className="container">
                        <div className="head">
                            <p className="text">Our Products</p>
                            <h2 className="heading">
                                For enterprises, our platform enables
                            </h2>
                        </div>
                        <div className="content text-center">
                            <div className="row features">
                                <div className="col-3 m-col-12 feature">
                                    <strong className="site-icon pay"></strong>
                                    <strong className="heading">Pay Later</strong>
                                    <span className="text"> Credit for micro retailers to buy on online B2B platforms/pay their distributor invoices </span>
                                </div>
                                <div className="col-3 m-col-12 feature">
                                    <strong className="site-icon invoice"></strong>
                                    <strong className="heading">Vendor Financing</strong>
                                    <span className="text"> Prevent your inventory stock-outs through your vendor credit line to pay your vendors. </span>
                                </div>
                                <div className="col-3 m-col-12 feature">
                                    <strong className="site-icon refresh"></strong>
                                    <strong className="heading">Receivable Financing</strong>
                                    <span className="text"> Get paid for your stuck receivables on your sales invoices upfront. </span>
                                </div>
                                <div className="col-3 m-col-12 feature">
                                    <strong className="site-icon cash"></strong>
                                    <strong className="heading">Cash Advance</strong>
                                    <span className="text"> Short term loans to micro retailers to take care of their working capital requirements. </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper colored pattern-constellation-light">
                    <div className="container">
                        <div className="content">
                            <div className="row">
                                <div className="col-4 m-col-12">
                                    <div>
                                        <h2 className="heading">
                                            Access to All <span className="text-accent">Credit Needs</span> of Enterprises & their Partners
                                        </h2>
                                        <p>
                                            Keep the cash flowing through our credit line & other products for your ecosystem
                                        </p>
                                    </div>
                                </div>
                                <div className="col-1 m-col-0"></div>
                                <div className="col-7 m-col-12">
                                    <div className="row">
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-user-voice-fill"></i>
                                            <div>
                                                <strong className="">Launch instant credit products</strong>
                                                <p>
                                                    Customize as per your specific needs
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-shield-star-fill"></i>
                                            <div>
                                                <strong className="">Access to multiple lenders</strong>
                                                <p>
                                                    reduced dependency on one lender helps faster scalibility of your programs
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-settings-fill"></i>
                                            <div>
                                                <strong className="">Integrate with your system</strong>
                                                <p>
                                                    Our APIs & SDK helps seamless flow of transactions of your customers and vendors
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-timer-flash-fill"></i>
                                            <div>
                                                <strong className="">Real-time information</strong>
                                                <p>
                                                    Our dashboard, reports give a birds eye view of your partners
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper pattern-constellation-light">
                    <div className="container">
                        <div className="content">
                            <div className="row">
                                <div className="col-4 m-col-12">
                                    <div>
                                        <h2 className="heading">
                                            Our <span className="text-accent-dark">API Driven</span> automation helps businesses run 10x faster
                                        </h2>
                                        <p>
                                            Do you own a technology stack for your business? Our API Stack helps NBFCs and enterprises automate Credit flow and reduce manual intervention thus making operations seamless and smooth.
                                        </p>
                                        <br />
                                        <a target="_blank" href="https://documenter.getpostman.com/view/9478456/TzRYe5Mf" className="btn btn-accent-dark"> Read Documentation </a>
                                    </div>
                                </div>
                                <div className="col-8 m-col-12">
                                    <img src={apiScreenshot} alt="API Screenshot" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper colored text-center intro pattern-constellation">
                    <div className="container">
                        <div className="head">
                            <h3 className="heading">Ready to become a Partner?</h3>
                            <p className="text">
                                Automate your business with our decentralised Credit Operating System. Start now by writing an email to explore solutions for your business.
                            </p>
                            <br />
                            <a href="mailto:support@saralpay.com" className="btn btn-accent"> Write an E-Mail </a>
                        </div>
                        <div className="content"></div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Home