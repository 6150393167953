import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles/App.scss';
import 'remixicon/fonts/remixicon.css'
import Footer from './components/layouts/Footer';
import Header from './components/layouts/Header';
import Home from './pages/Home';

function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route exact index element={<Home />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
